import cups from '../../../images/boxes/other/11.webp'
import cakes from '../../../images/boxes/other/10.webp'

const news = [
    {
        "url": "/Stakan4yky",
        "title": "Спішимо порадувати усіх наших клієнтів.",
        "img": cups,
        "description": "Шановні клієнти! Приватне підприємство \"Лата\" почало випуск нового виду продукції, а саме виробництво одноразових паперових стаканчиків під гарячі або холодні напої.\nПаперові стакани є універсальним інструментом для різних видів маркетингових комунікацій. На їх поверхню можна нанести будь-яке зображення, логотип або слоган. Використання паперового стаканчика дозволяє домогтися поставлених рекламних цілей і значною мірою оптимізувати маркетингові витрати.\nПриватне підприємство \"Лата\" приймає замовлення на виробництво стаканчиків з вашим унікальним дизайном.",
    },
    {
        "url": "/Novivesil",
        "title": "Про етикетку",
        "img": cakes,
        "description": "ПП \"Лата\" розробило новий дизайн подарункових, \"весільних\", і не тільки коробок з повноколірним надруком. Від тепер ще більший вибір пакування під солодощі.",
    },
]

export default news;